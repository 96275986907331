import React from "react"
import Accueil from "../components/Accueil/Accueil.jsx"
import Seo from "../components/SEO"

export default function Home() {

   React.useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://cdn.trustindex.io/loader.js?f8ca33b4440f543c33368388a15`
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);



  return (
    <>
      <Seo title="Accueil" />
      <Accueil />
    </>
  )
}

